/* You can add global styles to this file, and also import other style files */
.bg-gradient-WCC {
  background: linear-gradient(87deg, #2b313c 0, #4f596b 100%) !important;
}

.bg-gradient-WCC-blue {
  background: linear-gradient(87deg, #24272b 0, #2f3237 100%) !important;
}

input:disabled {
  background: #dddddd !important;
}

.text-center {
  text-align: center;
}
